.home-page-wrapper{
    // padding-top: 40px;

    .create-wrapper{
        width: 200px;
        margin-left: auto;
        margin-right: 0px;
    
    }

    .content-wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .pagination{
        width: 400px;
        display: flex;
        margin: auto;
        
    }
}