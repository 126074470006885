.main-page{
    display: flex;
    // height: 100vh;//sesuai screen yg terlihat
    box-sizing: border-box;
}

.left{
    flex: 1;
    background-color: gray;
    //height: 100%;
    .bg-image{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.right{
    width: 460px;
    background-color:white;
    //height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    .title{
        font-size: 20px;
        font-weight: bold;
        color: #4e4c4c;
    }
}