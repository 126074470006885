.button{
    padding: 7px;
    font-size: 11px;
    background-color: #87c73c;
    width: 100%;
    box-sizing: border-box;
    border-radius: 12px;
    border:none;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
}