.detail-blog-wrapper{
    .img-cover{
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
    .blog-title{
        margin: 0px;
        font-size: 30px;
        font-weight: bold;
        color: #2d2d2d;
        margin-top: 20px;
    }
    .blog-author{
        margin: 0px;
        font-size: 16px;
        color: grey;
    }
    .blog-body{
        font-size: 18px;
        line-height: 38px;
    }
}